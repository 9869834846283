import React from 'react'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const HeaderWithLink = ({ children, href, to, linkText, ...props }) => {
  return (
    <div {...props}>
      <StyledH2>{children}</StyledH2>
      <StyledP>
        {href ? <OutboundLink href={href}>{linkText} ›</OutboundLink> : null}
        {to ? <Link to={to}>{linkText} ›</Link> : null}
      </StyledP>
    </div>
  )
}

const StyledH2 = styled(H2)`
  display: inline-block;
  margin-right: 24px;
`

const StyledP = styled(P)`
  display: inline-block;
  margin: 0;

  a {
    color: ${props => props.theme.color.g400};
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

export default HeaderWithLink
