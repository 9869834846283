import React, { useEffect, useState, useRef } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import ReactImageGallery from 'react-image-gallery'
import Style from './style.js'
import Spacer from 'components/new/spacer'
import formatCategoryTitle from 'utils/format-category-title'
import { Bullet, BulletContainer } from 'components/new/bullet'
import { PausePlayButton, PausePlayButtonContainer } from 'components/new/pause-play-button'
import NavArrow from 'components/new/nav-arrow'

const PromotionsSlider = ({ autoPlay, kind, items, startIndex, ...props }) => {
  const galleryEl = useRef(null)
  const [itemsCount, setItemsCount] = useState(items.length)
  const [currentIndex, setCurrentIndex] = useState(startIndex)
  const [isPlaying, setIsPlaying] = useState(autoPlay)

  useEffect(() => {
    setItemsCount(items.length)
  }, [items.length])

  const onSlide = i => {
    setCurrentIndex(i)
  }

  const handlePausePlay = () => {
    setIsPlaying(!isPlaying)

    if (isPlaying) {
      galleryEl.current.pause()
    } else {
      galleryEl.current.play()
    }
  }
  return (
    <Container kind={kind}>
      <Style />
      <ReactImageGallery
        ref={galleryEl}
        items={items}
        autoPlay={autoPlay}
        startIndex={startIndex}
        onSlide={onSlide}
        {...props}
      />
      <CustomControls>
        <BulletContainer>
          {Array.from({ length: itemsCount }).map((_, i) => (
            <Bullet
              active={currentIndex === i}
              index={i}
              onClick={() => {
                galleryEl.current.slideToIndex(i)
              }}
              key={`Bullet${i}`}
            />
          ))}
        </BulletContainer>
        <PausePlayButtonContainer>
          <PausePlayButton onClick={handlePausePlay} isPlaying={isPlaying} />
        </PausePlayButtonContainer>
      </CustomControls>
    </Container>
  )
}

PromotionsSlider.defaultProps = {
  autoPlay: true,
  infinite: true,
  kind: 'default',
  renderItem: item => {
    return (
      <Grid>
        <ImgContainer>
          <GatsbyImage
            image={item.image}
            alt=''
            style={{ width: '100%', height: '100%' }}
            objectPosition='50% 50%'
            objectFit='cover'
          />
        </ImgContainer>
        <TextContainer>
          {Array.isArray(item.tags) && item.tags.length > 0 ? (
            <>
              <div>
                {item.tags.map(category => (
                  <TagLink to={`/promotions/${category}/`} key={`Tag-${category}`}>
                    {formatCategoryTitle(category)}
                  </TagLink>
                ))}
              </div>
              <Spacer size='s' />
            </>
          ) : null}
          <H3>{item.title}</H3>
          {item.shortDescription ? <P>{item.shortDescription}</P> : null}
          <Spacer size='s' />
          <ButtonContainer>
            <Button as={Link} to={item.link || item.slug}>
              View offer details
            </Button>
          </ButtonContainer>
          <OfferEndDate>Offer ends {item.endDateString}</OfferEndDate>
        </TextContainer>
      </Grid>
    )
  },
  renderLeftNav(onClick, disabled) {
    return <LeftNav direction='left' disabled={disabled} onClick={onClick} />
  },
  renderRightNav(onClick, disabled) {
    return <RightNav direction='right' disabled={disabled} onClick={onClick} />
  },
  showBullets: false,
  showFullscreenButton: false,
  showPlayButton: false,
  showThumbnails: false,
  slideInterval: 5000,
  startIndex: 0,
}

PromotionsSlider.propTypes = {
  kind: PropTypes.oneOf(['light', 'default']),
}

const CustomControls = styled.div`
  margin: 28px auto 0;
  text-align: center;
`

const StyledNavArrow = styled(NavArrow)`
  padding: 16px;

  @media (max-width: 799px) {
    display: none;
  }
`

const LeftNav = styled(StyledNavArrow)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
`

const RightNav = styled(StyledNavArrow)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
`

const Grid = styled.div`
  @media (min-width: 800px) {
    align-items: stretch;
    display: flex;
    flex-direction: row;

    > * {
      width: 50%;
    }
  }
`

const Container = styled.div`
  ${Grid} {
    background-color: ${props => {
      switch (props.kind) {
        case 'light':
          return props.theme.color.n10
        case 'default':
        default:
          return '#fff'
      }
    }};
  }
`

const ImgContainer = styled.div`
  :before {
    display: none;
  }

  :after {
    display: none;
  }

  @media (min-width: 800px) {
    :before {
      content: '';
      display: block;
      float: left;
      height: 0;
      margin-left: -1px;
      padding-bottom: 66.66%;
      width: 1px;
    }

    :after {
      clear: both;
      content: '';
      display: table;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      max-height: none;
      max-width: none;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.size.m};
  overflow: visible;
  white-space: initial;

  p {
    margin-bottom: 0;
  }

  @media (min-width: 800px) {
    padding: ${props => props.theme.size.l};
  }
`

const TagLink = styled(Link)`
  color: ${props => props.theme.color.g400};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0 5px 5px 0;
  text-decoration: none;

  :focus,
  :hover {
    text-decoration: underline;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const OfferEndDate = styled(P)`
  color: ${props => props.theme.color.n400};
  font-size: 14px;
  margin-bottom: 0;
`

export default PromotionsSlider
